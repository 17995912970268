import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { device } from '../../device';

export const Container = styled.section`
  grid-area: OUT;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media ${device.laptop} {
    margin-top: ${props => props.isMarginAndFontSize && '0'};
    margin-bottom: ${props => props.isMarginAndFontSize && '1.875rem'};
  }
`;

export const AllServices = styled.div`
  padding: ${props =>
    props.isPaddingContainer ? props.isPaddingContainer : '1.875rem'};
  margin-bottom: 3rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border-radius: 0.5rem;
  background: #f4f5f7;

  @media ${device.laptop} {
    padding: 1.25rem;
    margin-bottom: 1.875rem;
    margin-top: 1.875rem;
    align-items: flex-start;
    flex-direction: column;
  }

  @media ${device.tablet} {
    margin-bottom: 0.625rem;
  }
`;

export const ContainerText = styled.div`
  justify-content: center;
`;

export const Content = styled(ReactMarkdown)`
  width: 80%;
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  margin-left: 1.875rem;
  line-height: 1.375rem;

  @media ${device.laptop} {
    width: fit-content;
    font-size: ${props => (props.isMarginAndFontSize ? '0.875rem' : '1rem')};
    margin-left: 0;
  }

  > b {
    margin-left: 0;
  }
`;

export const BoxLogo = styled.figure`
  width: ${props => (props.containerWidth ? props.containerWidth : '32%')};

  @media ${device.laptop} {
    margin-bottom: 1.25rem;
  }

  @media ${device.mobile} {
    width: 59%;
  }

  @media ${device.mobile375} {
    width: 62%;
  }

  @media ${device.mobile320} {
    width: 78%;
  }
`;

export const LogoCert = styled.img`
  width: ${props =>
    props.heightCustomized ? props.widthCustomized : '10.5625rem'};
  height: ${props => props.heightCustomized && props.heightCustomized};
`;
